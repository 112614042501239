import { computed, ref } from 'vue';
import { useStore } from 'vuex';

const nodeContentLoading = ref(false);

export default () => {
    const store = useStore();

    // child nodes logic
    const childNodes = computed(() => store.getters['styleGuideTree/getChildNodes']);
    // const fetchChildNodes = async (payload) => {
    //     try {
    //         console.log('>>>>> Calling fetchChildNodes ...')
    //         await store.dispatch('styleGuideTree/fetchChildNodes', payload);
    //     } catch (err) {
    //         console.error(err);
    //     }
    // };

    // selected node logic
    const selectedNode = computed(() => store.getters['styleGuideTree/getSelectedNode']);
    const handleSelectedNode = async (payload) => {
        try {
            store.dispatch('assetsSearch/setSearchWithInFlag',{ searchWithInFlag: false });

            store.dispatch('assets/setSearchPage', 1)
            payload.page = store.getters['assets/getSearchPage']

            payload.limit = store.getters['assets/getSearchLimit']

            nodeContentLoading.value = true;
            store.dispatch('assetsSearch/updateSearchInitiated', { isSearchInitiated: false });
            store.dispatch('assets/resetAssets');
            const promiseStack = [store.dispatch('styleGuideTree/loadNodes', payload)];
            await Promise.all(promiseStack);
        } catch (err) {
            console.error(err);
        } finally {
            nodeContentLoading.value = false;
        }
    };

    return {
        childNodes,
        // fetchChildNodes,
        selectedNode,
        handleSelectedNode,
        nodeContentLoading
    };
};
