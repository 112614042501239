<template>
  <div class="pl-4 pr-2">
    <div class="border border-custom-gray-8 px-3 py-4 rounded-sm bg-custom-gray-11 mb-4 vh76Container">
      <div
        v-if="assetsSideBarFilters"
        class="flex mb-2"
      >
        <div class="w-1/2">
          <base-button
            class="flex items-center focus:outline-none"
            @click="switchSidebarContent('STYLE_GUIDES')"
          >
            <template #default>
              <img
                :src="require('@/assets/images/icons/folder.svg')"
                class="mr-1.5"
              >
              <span
                class="text-sm font-bold"
                :class="sidebarContent === 'STYLE_GUIDES' ? 'text-primary-red' : 'text-primary-gray'"
              >
                Style Guides
              </span>
            </template>
          </base-button>
        </div>
        <div class="w-1/2">
          <base-button
            class="flex items-center focus:outline-none"
            @click="switchSidebarContent('FILTERS')"
          >
            <template #default>
              <base-svg
                src="icons/list.svg"
                class="mr-1.5 font-bold h-3.5 w-3.5"
                :class="sidebarContent === 'FILTERS' ? 'text-primary-red' : 'text-primary-gray'"
              />
              <span
                class="text-sm font-bold"
                :class="sidebarContent === 'FILTERS' ? 'text-primary-red' : 'text-primary-gray'"
              >
                Filters
              </span>
            </template>
          </base-button>
        </div>
      </div>

      <div v-if="sidebarContent === 'STYLE_GUIDES' && (selectedNodeTitle || assignedSearchWithInTitle)" class="flex flex-col mb-4">
        <base-checkbox v-model="searchWithInFlag" container-class="flex ml-2">
          <template #label>
            <label class="text-sm ml-2 text-gray-500">
              Search within this folder:<br/><span class="text-base font-semibold text-gray-600">
                {{ selectedNodeTitle || assignedSearchWithInTitle }}
              </span>
            </label>
          </template>
        </base-checkbox>
      </div>

      <style-guide-category-list v-if="sidebarContent === 'STYLE_GUIDES'" />
      <assets-filter-list v-else-if="sidebarContent === 'FILTERS'" />
    </div>
  </div>
</template>

<script>
import { computed, defineAsyncComponent, ref, watch } from 'vue';
import { useStore } from 'vuex';
import useStyleGuideSidebar from './styleGuideSidebar.js';
import useStyleGuideDirectory from '@/hooks/styleGuideDirectory.js';

export default {
    name: 'StyleGuideSidebar',

    components: {
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue')),
        BaseCheckbox: defineAsyncComponent(() => import('@/components/generic-components/BaseCheckbox.vue')),
        BaseSvg: defineAsyncComponent(() => import('@/components/generic-components/BaseSvg.vue')),
        StyleGuideCategoryList: defineAsyncComponent(() => import('./StyleGuideCategoryList.vue')),
        AssetsFilterList: defineAsyncComponent(() => import('./AssetsFilterList.vue'))
    },

    setup () {
        const store = useStore();
        // search logic
        const searchWithInFlagComputed = computed(() => store.getters['assetsSearch/getSearchWithInFlag']);
        const searchWithInFlag = ref(store.getters['assetsSearch/getSearchWithInFlag']);

        const assignedSearchWithInTitle = computed(() => {
          let tmp =  store.getters['assetsSearch/getSearchWithInParams']
          if (!tmp) return
          return Object.values(tmp).pop()
        });

        const { selectedNode } = useStyleGuideDirectory();
        const selectedNodeTitle = computed(() => selectedNode.value && selectedNode.value.title ? selectedNode.value.title : '');
        const searchInitiated = computed(() => store.getters['assetsSearch/getSearchInitiated']);
        const assetsSideBarFilters = computed(() => store.getters['assets/getAssetsFilters']);

        watch(
            () => assetsSideBarFilters.value,
            () => {
                if (assetsSideBarFilters?.value && assetsSideBarFilters.value.length
                  && assetsSideBarFilters.value.some(e =>e.options && e.options.length)) {
                    switchSidebarContent('FILTERS');
                } else {
                    switchSidebarContent('STYLE_GUIDES');
                }
            }
        );

        watch(
            () => searchWithInFlagComputed.value,
            () => {
              searchWithInFlag.value = searchWithInFlagComputed.value
            }
        )

        watch(
            () => searchWithInFlag.value,
            () => {
                store.dispatch('assetsSearch/setSearchWithInFlag', {
                  selectedNodeStack: store.getters['styleGuideTree/getSelectedNodeStack'],
                  searchWithInFlag: searchWithInFlag.value,
                });
            }
        );

        // sidebar content logic
        const { sidebarContent, switchSidebarContent } = useStyleGuideSidebar();

        return {
            searchWithInFlag,
            selectedNodeTitle,
            searchInitiated,
            assetsSideBarFilters,
            // sidebar content
            sidebarContent,
            switchSidebarContent,
            assignedSearchWithInTitle
        };
    }

};
</script>

<style scoped>

.vh76Container{
  max-height: 76vh;
  min-height: 76vh;
  overflow: scroll;
}


/* Works on Firefox
* {
  scrollbar-width: auto;
  scrollbar-color: #7600009f #ffffff00;
}
*/

/* Works on Chrome, Edge, and Safari
*::-webkit-scrollbar {
  width: .18rem;
}

*::-webkit-scrollbar-track {
}

*::-webkit-scrollbar-thumb {
  background-color: #6f6f6f9f;
  border-radius: 2rem;
}
*/

</style>
